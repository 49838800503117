$color-main-bg: #7115d8;
$path-fill-1: url(#gradient1);
$path-fill-2: url(#gradient2);
$path-fill-3: url(#gradient3);


.shape-overlays {
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
}

.shape-overlays.is-opened {
	pointer-events: auto;
}

.shape-overlays__path.shape-overlays__path-1 {
	fill: $path-fill-1;
}

.shape-overlays__path.shape-overlays__path-2 {
	fill: $path-fill-2;
}

.shape-overlays__path.shape-overlays__path-3 {
	fill: $path-fill-3;
}
