@import 'models/_variables-and-functions.module.scss';

.COSMIC--container {
  height: 100%;
  position: absolute;
  top: 0px;
  width: 100%;
}

.COSMIC--shape-container {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.COSMIC--content {
  position: absolute;
  top: 0px;
  height: 100%;
  overflow-y: scroll;
  color: white;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.COSMIC--copy {
  // font-family: "times-new-roman", serif;
  font-family: "garamond-premier-pro-display", "garamond premier pro display", "garamond-premier-pro", "garamond premier pro", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px; 
  line-height: 12px * 3;
  width: 100%;
  text-align: justify;
  color: black;
}



// ._COSMIC--copy {
//   margin-top: 35px;
//   @extend %CASE-STUDY-FONT;
//   // font-size: 30px;
//   background-color: white;
//   color: black;
//   width: 500px;
//   padding: 1em;
//   margin: auto;
//   margin-top: 2em;
//   span {
//     // background-color: rgba(0,0,0,.5);
//     -moz-box-decoration-break:clone;
//     -webkit-box-decoration-break:clone;
//     box-decoration-break:clone;
//     padding: 2px 10px;
//   }

// }

.COSMIC--case-study {
  font-family: garamond-premier-pro-display, serif;
  // font-family: "helvetica neue";
  font-weight: 300;
  // font-style: italic;
  text-transform: uppercase;
  font-size: 30px; 
  @extend %CONTENT;
  padding-bottom: 7px; 
  // border: 1px solid red;
  // left: 0px;
  text-align: center;
  margin-bottom: 20px;
  opacity: 0;

  // ALTERNATE
  // margin-bottom: 20px;
}  

.COSMIC--triangle {
  padding-top: 5em;
  position: relative;
  top: 3px;
  // Control distance between bottom triangle image and text
  // numbers increase as size decreases because
  // narrow phones are proportionally longer
  @include only(phone-narrow) {

    @include absolute-center(350);  
  }
  @include only(phone-portrait) {
    @include absolute-center(300);  
  }
  @include only(phone-landscape) {
    // problem
    @include absolute-center(200);  
  }
  @include only(tablet-narrow) {
    @include absolute-center(135);  
  }
  @include only(tablet-portrait) {
    @include absolute-center(125);  
  }
  @include only(tablet-landscape) {
    $triangleWidth: 93;
    width: 0vw + $triangleWidth;
    // left: 0vw + leftPercentToCenter( $triangleWidth );
  }
  
  @include above(tablet-landscape) {
    width: 75%;
  }
  // width: 2000px;
  // left: -1000px;
}

.COSMIC--copy-right-shape {
  float: right;
  width: 50%;
  height: 700px;
  shape-outside: polygon( 
    0% 0%,
    100% 99%,
    100% 0%, 
    0% 0%
  );
  //polygon(0% 0%, 100% 99%,100% 0%, 0% 0%)
}

.COSMIC--copy-left-shape {
  float: left;
  width: 50%;
  height: 700px;
  shape-outside: polygon( 
    0% 100%, 
    100% 0%,
    0% 0%,
    0% 100%
  );

}

.COSMIC--core-copy {
  @include only(phone-narrow) {
    // narrow text
    width: 70%;
    padding-left:30%;
    // padding-left: calc( 30% );
  }
  @include only(phone-portrait) {

    width: 70%;
    padding-left: calc( 30% );
  }
}

.COSMIC--copy-container {
  position: relative;
  max-width: 725px;
  // border: 1px solid green;
  margin: auto;
}

.COSMIC--below-triangle {
  background-color: white;
  height: 000px;
  position: relative;
  top: -3px;
  &__space-fix {
    display: none;
    background: white;
    width: 100%;
    height:2px;
    position: absolute;
    bottom: -5px;
  }
}


.COSMIC--bottom-triangle {
  $debug: false;
  $widthPercent: 34;
  $landscapeWidthPercent: 40;
  $belowTabletLandscapeWidthPercent: 50;
  
  position: absolute; 
  display: block;
  bottom: -3px;
  margin: auto;
  // set size of triangle
  @include absolute-center(34);  
  @include only(phone-narrow) {
    @include debug-border( $debug, hotpink);
    @include absolute-center(170);
  }
  @include only(phone-portrait){
    @include debug-border( $debug, green);
    @include absolute-center(170);  
  }
  @include only(phone-landscape){
    @include debug-border( $debug, blue);
    @include absolute-center(80);
  }
  @include only(tablet-narrow) {
    @include debug-border( $debug, salmon);
    @include absolute-center(60);
  }
  @include only(tablet-portrait) {
    @include debug-border( $debug, orange);
    @include absolute-center(60);  
  }
  @include only(tablet-landscape) {
    @include debug-border( $debug, purple);
    @include absolute-center(44);  
  }
  @include only(laptop) {
    @include debug-border( $debug, red);
    @include absolute-center(38);  
  }
  @include above(laptop) {
    @include debug-border( $debug, rgb(108, 136, 72) );
    @include absolute-center(44);   
  }

  &__image {
    transition: transform .5s ease-out;
    will-change: transform;
    transform-origin: 50% 40%;
    
  }

  &__image:hover {
    transform: scale(1.5);
  }
  // display: none;
  // border: 1px solid red;
}

.COSMIC--triangle-content {
  position: absolute;
  top: 10em;
  width: 100%;
  @include only(phone-narrow) {
    //a
    @include absolute-center(200);
  }
  @include only(phone-portrait) {
    // make text slim
    @include absolute-center(200);  
  }
  // border: 5px dotted pink;
}


// .COSMIC--copy-left-shape {
//   float: right;
//   width: 100%;
//   height: 400px;
//   border: 1px solid red;
//   shape-outside: polygon(100% 0%, 50% 0%, 100% 100%, 100% 0%);

// }
//apt-get -s dist-upgrade |grep "^Inst" |grep -i securi
//sudo apt-get -s dist-upgrade | grep "^Inst" | grep -i securi | awk -F " " {'print $2'} | xargs sapt-get install