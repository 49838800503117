
.bbb { 
  height:2000px; 
  left:0%; 
  position:fixed; 
  top:0; 
  width: 200%; 
}

.bro {
  // clip-path: url(#myClip);
  height: 200%;
  width: 100%;
}


.brah {
  // height: 200%;
  width: 100%;
  position: absolute;
  // z-index: 1;
}

.paneContainer {
  display: none;
}