@import './variables-and-functions.module';

%PAGE--container {
  padding-top: 60px;
  width: 85%;
  margin: auto;
  
  @include size( any-phone-only ){
    padding-top: 40px;
  }
  @include size ( tablet-landscape-up ) {
    max-width: 900px;
  }
}