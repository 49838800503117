@import 'models/_variables-and-functions.module.scss';
$belowBreakPoint: map-get-strict( $mainGrid, mainGridMobileBelow );

.container {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}

.contactUs {
  position: relative;
  width: 62%;
  margin: auto;
  top: 42%;
  @include below( $belowBreakPoint ) {
    top: 23.8%;
  }
}

.info {
  box-sizing: border-box;  
  position: absolute;
  bottom: 5%;
  left: 3.5%;
  width: 100%;
  @include below( $belowBreakPoint ) {
    top: 48%;
    left: 0;
  }
}

%infoFont {
  font-size: 1.9vw;
  font-family: Inter;
  // font-size: 19px; font-family: proxima-nova;
  color: black;
  font-weight: 700;
  text-decoration: none;
  display: block;
  font-size: 16px;
  
  @include below( $belowBreakPoint ) {
    display: block;
    margin: auto;
    text-align: center;
    font-size: 3.5vw;
  }
}

.info__p {
  @extend %infoFont;
}

.info__e {
  @extend %infoFont;
  @include above( $belowBreakPoint ) {
    margin-top: 2px;
  }
  @include below( $belowBreakPoint ) {
    margin-top: 1%;
  }



}

.social {
  position: absolute;
  width: 50%;
  right: 3.5%;
  bottom: 5%;
  text-align: right;
  @include below( $belowBreakPoint ) {
    width: 100%;
    right: 0;
    text-align: center;
    bottom: 10%;
    // border: 1px solid red;
  }
}

.social__item {
  margin-right: 3%;
  @include below( $belowBreakPoint ) {
    margin-right: 7%;
    // width: 4vw;
    // border: 1px solid purple;
    // width: 7vw;
  }
}

.social__item:last-of-type {
  margin-right: 0%;
}

.social__twitter {
  display: inline-block;
  @include above( $belowBreakPoint ) {
    width: 15%;
  }
  @include below( $belowBreakPoint ) {
    width: 7vw;
  }
}

.social__github {
  display: inline-block;
  @include above( $belowBreakPoint ) {
    width: 13%;
  }
  @include below( $belowBreakPoint ) {
    width: 6vw;
  }
}

.social__in {
  display: inline-block;
  margin-left: .5%;
  display: inline-block;
  @include above( $belowBreakPoint ) {
    width: 12%;
  }
  @include below( $belowBreakPoint ) {
    width: 6vw;
  }
}

.social__devto {
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: .5%;
  @include above( $belowBreakPoint ) {
    width: 12.2%;
  }
  @include below( $belowBreakPoint ) {
    width: 5.7vw;
  }
}