@import 'models/_variables-and-functions.module.scss';

$belowBreakPoint: map-get-strict( $mainGrid, mainGridMobileBelow );

.cosmicLayout {
  grid-template-areas:
    'cosmic   cosmic   endpain'
    'cosmic   cosmic   gjelina'
    'articles contact  contact'
    'articles contact  contact';
  @include below( $belowBreakPoint ) {
    grid-template-areas:
    'cosmic'
    'endpain'
    'gjelina'
    'articles'
    'contact';
  }
}


.endpainLayout {
  grid-template-areas:
    'endpain  endpain  cosmic'
    'endpain  endpain  gjelina'
    'articles contact  contact'
    'articles contact  contact';
  @include below( $belowBreakPoint ) {
    grid-template-areas:
    'endpain'
    'cosmic'
    'gjelina'
    'articles'
    'contact';
  }
}


.gridContainer {
  $padding: map-get-strict( $mainGrid, mainGridSidesPadding );
  $width: map-get-strict( $mainGrid, mainGridWidth);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 0 $padding;
  width: 100%;
  max-width: 900px + ( $padding * 2 );
  /* TODO: responsive */
  min-width: 300px; 
  margin: auto;
  @include below( $belowBreakPoint ) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.gridItem{
  display: block;
  cursor: auto;
}

.gridItem[href] {
  cursor: pointer;
}

.gridItem::before {
  content: " ";
  display: block;
  // background:red;
  padding-top: 62%;
  width: 0px;
  float: left;
}



@mixin background-cover($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center; /* Center the image */
}

.cosmic {
  cursor: pointer;
  grid-area: cosmic;
  @include background-cover( 
    map-get($images, imageCosmicMan) 
  );
  background-size: 100%;
  background-position: center 65%;
 
}

.cosmic:before {
  content: "";
  height: 0;
  display: inline-block;
  padding-top: 61.8%;
  width: 1px;
  position: relative;
}

.gjelina {
  cursor: pointer;
  @include background-cover( 
    map-get($images, imageGjelinaWide) 
  );
  grid-area: gjelina;
  // background: white;
}

.gta {
  cursor: pointer;
  grid-area: gta;
  background: white;
}

.endpain {
  cursor: pointer;
  grid-area: endpain;
  background: white;
  @include background-cover( 
    map-get($images, imageEpWide) 
  );
  background-size: 101%;
}

.contact {
  grid-area: contact;
  @include background-cover(
    map-get( $images, imageContact )
  );
  position: relative;
}

.articles {
  cursor: pointer;
  @include background-cover(
    // 'http://folklor.la/media/stories/15/story_images/124/story_image/124_story_image_1515815341.png'
    map-get-strict( $images, imageGta )
  );
  background-size: cover;
  background-position: 30%;
  grid-area: articles;
}