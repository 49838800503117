@import 'models/_variables-and-functions.module.scss';
@import 'models/_extend.module.scss';

$belowBreakPoint: map-get-strict( $mainGrid, mainGridMobileBelow );

$debug: false;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$space-below-box: 3.5em;
$space-above-box: 3em;
$left-right-box-margin: auto;
$inner-content-top-padding: 1em;
$inner-content-bottom-padding: 1em;

.ENDPAIN--container {
  @extend %PAGE--container;
  @include below($belowBreakPoint) {
    width: 95%;
  }
}

.ENDPAIN--flower-content-container {
  background-color: #f0f0f0; 
  @extend %CONTENT;
  top: 5%;
  padding-bottom: $space-below-box;
  margin-bottom: 2em;
  @include only(phone-portrait){
    // border: 1px solid red;
    @include debug-border( $debug, hotpink);
    // height: 140vw;
  }
  @include only(phone-landscape){
    // border: 1px solid red;
    @include debug-border( $debug, green);
    // height: 100vh;
    // height: calc( (1vw/1vh) * 100vw);
  }
  @include only(phone-narrow) {
    @include debug-border( $debug, orange);

    // height: 133vh;
    // border: 1px solid red;
  }
}

.ENDPAIN--flower-container {
  position: absolute; top: 0px; width: 100%; margin: 0px; padding: 0px; height: 100%;
}

.ENDPAIN--flower {
  z-index: 1; position: absolute; top: 0px; height: 100%;
}

.ENDPAIN--logo-container {
  color: white; 
  background-color: black; 
  position: relative; 
  padding-top: 19%;
}

.ENDPAIN--logo-left {
  z-index: 2; position: absolute; top: 0px; pointer-events: none;
  fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;
  @include below(phone-narrow) {
    top: -1px;
  }
}

.ENDPAIN--logo-right {
  z-index: 0; position: absolute; top: 0px; pointer-events: none;
  fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;
  @include below(phone-narrow) {
    top: -1px;
  }
}

.ENDPAIN--flower-content-divider {
  position: relative; 
  z-index: 2; 
  width: 100%; 
  height: 25px; 
  background-color: white;
  @include size( any-phone-only ) {
    // border: 1px solid red;
    height: 12px;
  }
  top: -2px;

}


.ENDPAIN--flower-content-introduction-container {
  z-index: 5; 
  position: relative; 
  background-color:white; 
  // font-family: Helvetica, Arial, sans-serif; 
  @extend %CASE-STUDY-FONT;
  width: 61.8%; 
  max-width: 500px;

  // border-radius: 0 20px 0 0;
  border-radius: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  margin: $space-above-box $left-right-box-margin auto $left-right-box-margin;
  // border: 1px solid red;
  @include below( phone-landscape ) {
    // font-size: 16px;
    opacity: 0;
    height: 50px;
    pointer-events: none;
    ///
    width: 80%;
    height: auto;
    // font-size: 14px;
    top: -15px;
  }
  @include below( tablet-narrow ) {
    width: 80%;
    // border: 1px solid blue;
  }
  @include only( tablet-portrait ) {
    width: 77%;
    // border: 1px solid orange;
  }
  @include below($belowBreakPoint) {
    font-size: 4.2vw;
    font-family: proxima-nova, "proxima nova", helvetica, san-serif;

  }
}

.ENDPAIN--flower-content-introduction-container ul {
  // border: 1px solid red;
  margin: 0px;
  padding: .7em 1.5em 1.0em 2em;
  li {
    margin-bottom: .5em;
  }
}



.ENDPAIN--flower-content-technology-container {
  @extend .ENDPAIN--flower-content-introduction-container;
}

.ENDPAIN--flower-content-paragraph {
  margin: 0px;
  padding: 1.5em;
  padding-bottom: 0px;
  &__first {
    padding-top: $inner-content-top-padding;
    // margin-top: 2em;
    // padding-top: 2em;
  }
  &__last {
    padding-bottom: $inner-content-bottom-padding;
  }
}


.ENDPAIN--flower-content-paragraph-technology {
  margin: 0px;
  padding: 1em;
  padding-bottom: 0px;
  &__first {
    padding-top: 10px;
    // margin-top: 2em;
    // padding-top: 2em;
  }
  &__last {
    padding-bottom: $inner-content-bottom-padding;
  }
}


.ENDPAIN--case-study {
  // font-family: Helvetica, Arial, sans-serif;
  font-family: "garamond-premier-pro-display", "garamond premier pro display", "garamond-premier-pro", "garamond premier pro", serif;
  font-weight: 300;
  font-style: italic;
  font-size: 30px; 
  @extend %CONTENT;
  padding-bottom: 7px;
  // @include below( phone-landscape ){
  //   // border: 1px solid royalblue;
  //   font-size: 1.4em;
  //   padding-bottom: 2px;
  // }
  @include below( $belowBreakPoint ) {
    font-size: 6vw;
    padding-bottom: .3%;
  }
}

.ENDPAIN--technology {
  // font-family: Helvetica, Arial, sans-serif;
  @extend .ENDPAIN--case-study;
  padding-top: $inner-content-top-padding;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}



$boxSize: 25px;
.ENDPAIN--flower-content-curve {
  position: absolute;
  // border: 1px solid red;
  width: $boxSize; 
  height: $boxSize;
  right: -1 * $boxSize;
  bottom: 0px;
  display: none;
}

.ENDPAIN--pyramid-container {
  width: 250px;
  margin: auto;
  margin-top: 75px;
  text-align: center;
  .left {
    fill: hsl(152,44,41);  
  }
  .right {
    fill: hsl(159,40,65);
  }
}

.ENDPAIN--section-wide {
  @extend .ENDPAIN--flower-content-introduction-container;
  // max-width: 100%;
  // width: 90%;
  // position: relative;
  // top: -80px;
}