@import 'models/_variables-and-functions.module.scss';
$belowBreakPoint: map-get-strict( $mainGrid, mainGridMobileBelow );
// $previousBreakPoint: map-get-strict( $only-from-map, $belowBreakPoint);
$opacityTransition: opacity 1s ease;
$debug: false;
.pyramid {
  :global .right {
    opacity: .5;
  }  
}

.container {
  margin: auto;
  position: relative;
  width: 100%;
}

.pyramidLogoContainer {
  // border: 1px solid red;
  max-width: 1000px;
  margin: auto;

  .logoContainer {
    // border: 1px solid red;
    position: relative;
    text-align: center;
    // top: -42vw;
    top: -420px;
    transition: $opacityTransition;
    width: 100%;
  }

  .logoContainer svg {
    width: 35%;
  }


}


/*
  THE ENTIRE OCEAN AREA
*/
.seaContainer {
  position: relative;
  top: -25vw;
  @include above( tablet-portrait ) {
    top: -250px;
  }
  // top: -25vw;

  /*
    Programmatic behavior
  */
  .seaReady {
    opacity: 1;
  }

  .seaLoading {
    opacity: 0;
  }

  /*
    Sea is really a water container
  */
  .sea {
    overflow: hidden;
    position: relative;
    transition: all 1s ease;
    /*
      Water is the dynamically flowing area
    */
    .water {
      display: block;
      height: 150px;
      margin: 0px;
      padding: 0px;
      position: relative;
      width: 100%;    
      top: 3px;
    }
  }

  .submerged {
    background-color:$background;
    padding: 0px;
    height: auto;
    padding-bottom: 4em;
    margin: 0px;
    
    position: relative;
    transition: all 1s ease;
    width: 100%;
    @include only( phone-narrow ) {
      @include debug-border( $debug, lime);
    }
    @include only( phone-portrait ) {
      @include debug-border( $debug, hotpink);
    }
    @include only( phone-landscape ) {
      // height: 100vw;
      @include debug-border( $debug, green);
    }
    @include above( phone-landscape ) {
      // height: 100vw;
      @include debug-border( $debug, orange);
    }
  }  
}

.bottomFiller {
  transition: $opacityTransition;
  position: absolute;
  text-align: center;
  display: block;
  // border: 1px solid red;
  // border: 10px solid purple;
  background-color: $background;
  // background-color: black;
  padding-top: 25%;
  width: 100%;
  @include above(tablet-portrait) {
    padding-top: 250px;
  }
  
}

.table {
  display: table;
  width: 100%;
  max-width: 1000px;
  border-spacing: 15px;
  margin: auto;
}

.row {
  display: table-row;
}


// Boxes

.quoteContainer {
  width: 100%;
  // max-width: map-get-strict($mainGrid, mainGridWidth );
  // padding: 0 map-get-strict( $mainGrid, mainGridSidesPadding);
  // font-size: 1.08em; font-family: proxima-nova;
  font-size: .9em; 
  font-family: Inter;
  font-weight: 700;
  position: absolute;
  // border: 1px solid red;
  top: 30px;
  color: whitesmoke;
  @include only( $belowBreakPoint ) {
    font-size: 2.5vw;
    top: -1vw;
  }
  @include below( breakpoint-before( $belowBreakPoint) ) {
    font-size: 3vw;
    top: -4vw;
  }
  // @debug $belowBreakPoint;
  // @debug breakpoint-before( $belowBreakPoint);
  // @include between( $previousBreakPoint, $belowBreakPoint ){
  //   font-size: 2.5vw;
  //   top: -3vw;
  // }

}
.quote {
  width: 100%;
  max-width: map-get-strict($mainGrid, mainGridWidth );
  padding: 0 map-get-strict( $mainGrid, mainGridSidesPadding);
  margin: auto;
}

.quote__break {
  @include above( $belowBreakPoint ){ 
    display: none;
  }
}

.quote__text {
  color: whitesmoke;
  margin: auto;
  // &::before {
  //   content: "“";
  // }
  // &::after {
  //   content: "”";
  // }
}

.quote__author {
  position: relative;
  top: .8em;
  color: whitesmoke;
  &::before {
    content: "— ";
  }
}

.offsetCorrection {
  position: absolute;
  background: $background;
  width: 100%;
  height: 250px;
}
