@import 'modules/sassdash/_index.scss';

$background: hsl(209, 66%, 33%);
$background: #232027;
 
$phone-narrow-boundary: 350px; 
$phone-upper-boundary: 480px;
$phone-wide-upper-boundary: 670px; // 785
$tablet-narrow-upper-boundary: 768px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1200px;
$laptop-boundary: 1400px;

$exports: (
  phone-narrow-boundary: $phone-narrow-boundary,
  phone-upper-boundary: $phone-upper-boundary,
  phone-wide-upper-boundary: $phone-wide-upper-boundary,
  tablet-narrow-upper-boundary: $tablet-narrow-upper-boundary,
  tablet-portrait-upper-boundary: $tablet-portrait-upper-boundary,
  tablet-landscape-upper-boundary: $tablet-landscape-upper-boundary,
  laptop-boundary: $laptop-boundary,
);

@function absolute-image($image) {
  $domain: 'https://hemaka-media-lmtpeasgdr.now.sh';
  @return '#{$domain}/#{$image}';
}

$images: (
  imageContact: absolute-image('contact-pattern.jpg'),
  imageGjelinaWide: absolute-image('gjelina-wide-small.jpg'),
  imageCosmicManSquare: absolute-image('cs-walk-up-square.jpg'),
  imageCosmicMan: absolute-image('cs-walk-up.jpg'),
  imageEpSquare: absolute-image('ep-square.jpg'),
  imageEpWide: absolute-image('ep-wide.jpg'),
  imageSky: absolute-image('sky-image.jpg'),
  imageGta: absolute-image('gta.jpg')
);

$colors: (
  seaColor: $background,
);

$mainGrid: (
  mainGridWidth: 900px,
  mainGridSidesPadding: 25px,
  mainGridMobileBelow: phone-landscape,
);

// Options include:
//   phone-portrait-only
//   phone-landscape-only
//   tablet-portrait-until
//   tablet-portrait-up
//   tablet-landscape-up
//   laptop-up

@mixin size($range) {
  @if $range == any-phone-only {
    @media (max-width: #{$phone-wide-upper-boundary - 1}) { @content; }
  } @else if $range == phone-narrow-only {
    @media (max-width: #{$phone-narrow-boundary - 1}) { @content; }
  } @else if $range == phone-portrait-only {
    @media (min-width: #{$phone-narrow-boundary}) and (max-width: #{$phone-upper-boundary - 1}) { @content; }
  } @else if $range == phone-landscape-only {
    @media (min-width: $phone-upper-boundary) and (max-width: #{$phone-wide-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-portrait-only {
    @media (min-width: $phone-wide-upper-boundary) and (max-width: #{$tablet-portrait-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-landscape-only {
    @media (min-width: $tablet-portrait-upper-boundary) and (max-width: #{$tablet-landscape-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-portrait-until {
    @media (max-width: #{$phone-wide-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-landscape-until {
    @media (max-width: #{$tablet-portrait-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-wide-upper-boundary) { @content; }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) { @content; }
  } @else if $range == laptop-up {
    @media (min-width: $tablet-landscape-upper-boundary) { @content; }
  }
}


:export {
  @each $key, $value in $exports {
    #{$key}: $value;
  } 
  @each $key, $value in $images {
    #{$key}: $value;
  } 
  @each $key, $value in $colors {
    #{$key}: $value;
  } 
  @each $key, $value in $mainGrid {
    #{$key}: $value;
  } 
}

%CONTENT {
  width: 90%;
  left: 5%;
  position: relative;
}

%CASE-STUDY-FONT {
  font-family: "garamond-premier-pro-display", "garamond premier pro display", "garamond-premier-pro", "garamond premier pro", serif;
  // font-family: proxima, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px; 
  line-height: 1.5em;
}

@function left-percent-to-center($widthPercent) {
  @return 50 - ($widthPercent/2);
}

@function leftPercentToCenter($widthPercent) {
  @return 50 - ($widthPercent/2);
}


@mixin absolute-center($width) {
  width: 0% + $width;
  left: 0% + leftPercentToCenter($width);
}



//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  zero: 0,
  phone-narrow: $phone-narrow-boundary,
  phone-portrait: $phone-upper-boundary,
  phone-landscape: $phone-wide-upper-boundary,
  tablet-narrow: $tablet-narrow-upper-boundary,
  tablet-portrait: $tablet-portrait-upper-boundary,
  tablet-landscape: $tablet-landscape-upper-boundary,
  laptop: $laptop-boundary,
);

$breakpoint-list: _keys($breakpoints);

@function element-before( $list, $element, $before: 1, $error: "" ) {
  @if($before < 1) {
    @error "before value must be greater than 0"
  }
  $beforeIndex: _index-of( $list, $element ) - $before;
  @if ( $beforeIndex > 0 ) {
    // @debug "nextline";
    @return nth( $list, $beforeIndex );
  } @else {
    $error: if( $error == "",
      "Element #{$element} was at index #{$beforeIndex}; requested element is out of bounds",
      $error);
    @error $error;
  }
};

@function breakpoint-before( $element, $before: 1 ) {
  @return element-before( $breakpoint-list, $element , $before );
}

// $only-from-map: (
//   phone-narrow: 0,
//   phone-portrait: map-get($breakpoints, phone-narrow ),
//   phone-landscape: map-get($breakpoints, phone-portrait ),
//   tablet-narrow: map-get($breakpoints, phone-landscape ),
//   tablet-portrait: map-get($breakpoints, tablet-narrow ),
//   tablet-landscape: map-get($breakpoints, tablet-portrait ),
//   laptop: map-get($breakpoints, tablet-landscape ),
// );


@function map-get-strict($obj, $key, $error: "") {
  @if map-has-key( $obj, $key ) {
    @return map-get( $obj, $key );
  } @else {
    @if $error == "" {
      $error: "Key #{$key} not found in object.";
    }
    @error $error;
  }
};


// @mixin only($breakpoint) {
//   $error: 'Invalid breakpoint: #{$breakpoint}.';
//   $only-from: map-get-strict(
//     $only-from-map, 
//     $breakpoint,
//     $error
//   );

//   $only-to: map-get-strict(
//     $breakpoints, 
//     $breakpoint,
//     $error
//   ) - 1;

//   @media ( min-width: $only-from ) and ( max-width: $only-to ) {
//     @content;
//   };
// }

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-from(sm) {}
@mixin above($breakpoint) {

  $bpv: map-get-strict($breakpoints,$breakpoint);
  @media ( min-width: $bpv ) {
    @content;
  }
}



//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin below($breakpoint) {

  $bpv: map-get-strict(
    $breakpoints, 
    $breakpoint, 
    'Invalid breakpoint: #{$breakpoint}.'
  );

  @media ( max-width: $bpv - 1 ) {
    @content;
  };

}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin between($lower, $upper) {
  $lower-breakpoint: map-get-strict($breakpoints, $lower, 'Your lower breakpoint was invalid: #{$lower}.');
  $upper-breakpoint: map-get-strict($breakpoints, $upper, 'Your upper breakpoint was invalid: #{$upper}.');
  @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
    @content;
  }
}

@mixin only($breakpoint) {
  @include between(
    breakpoint-before($breakpoint),
    $breakpoint) {
      @content;
    }
}

///////


@mixin debug-border($debug, $color) {
  @if $debug {
    border: 10px solid $color;
  } @else {
    // 
  }
}
 
// @error "H3ERE: #{map-get-strict($breakpoints, phone-narrow)}";