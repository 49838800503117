@import 'models/_variables-and-functions.module.scss';
@import 'models/_extend.module.scss';

* { -webkit-tap-highlight-color:rgba(0,0,0,0); }


.CLOSE--upper-right {
  width: 7%;
  position: absolute;
  right: 2%;
  top: 2%;
  z-index: 10;
  cursor: pointer;
  path {
    stroke-width: 3px;
  }

  @include size( phone-narrow-only ) {
    width: 10vw;
    right: 1vw;
    top: 1vw;
  }

  @include size( phone-portrait-only ){
    width: 10vw;
    right: 1vw;
    top: 1vw;
    // padding-top: 1.75%;
  }

  @include size( phone-landscape-only ){
    width: 9.5vw;
    right: .75vw;
    top: .75vw;
    // padding-top: 1.75%;
  }

  @include size ( tablet-landscape-up ) {
    max-width: 80px;
  }

}



.PAGE--container {
  padding-top: 60px;
  width: 85%;
  margin: auto;
  
  @include size( any-phone-only ){
    padding-top: 40px;
  }
  @include size ( tablet-landscape-up ) {
    max-width: 900px;
  }
 
}


