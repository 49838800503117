
.pyramid {
  :global .right {
    opacity: .5;
  }  
}

.not-visible {
  opacity: 0
}


.visible {
  opacity: 1
}
.pyramid {
  // border: 1px solid red;
}
